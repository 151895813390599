@import '../../variables/variables.less';

#support {
    background-color: @White;

    @media @desktop,
    @tablet {
        padding: 30px 60px;
    }

    h2 {
        text-align: center;
        margin-bottom: 70px;
    }

    .accordion {
        list-style: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    .toggler {
        margin-left: auto;
        position: relative;

        @media @desktop,
        @tablet {
            margin-right: 25px;
        }
    }

    .accordion-item {
        border-top: 1px solid #ceced9;
        padding: 0;

        ul {
            margin: 0 0 10px 0;
            list-style-type: disc;
            padding-left: revert;
        }
        &.first {
            border: none;
        }

        .accordion-title {
            position: relative;
            display: flex;
            align-items: center;
            padding: 30px 20px 30px 20px;

            @media @desktop,
            @tablet {
                padding: 30px 0 30px 0;
            }

            h4 {
                text-align: left;
                margin-bottom: 0;
                line-height: normal;

                @media @desktop,
                @tablet {
                    margin-right: 180px;
                }

                @media @mobile {
                    max-width: 90%;
                }
            }
        }

        &.expand {
            background: @White;

            .toggler {
                transform: rotate3d(0, 0, 1, 180deg);
            }
        }

        .accordion-content {
            font-size: 18px;
            color: @Black;
            text-align: left;
            padding-bottom: 0;
            cursor: default;

            @media @mobile {
                padding: 0 20px 0 20px;
            }
        }

        .toggler {
            cursor: pointer;
            display: block;

            &:after {
                content: '';
                border: 1px solid @Black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
                position: relative;
                top: -3px;
            }
        }

        /* ## closed and open state

    JS toggles the .expand class on info-box
    Basic panel is closed end expanded is opened
     */
        .expanded {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-height .25s ease, opacity .25s ease;
            position: relative;

            &:focus {
                outline: 0;
            }

            @media @mobile {
                position: static;
            }
        }

        &.expand {

            .expanded {
                max-height: 5000px;
                opacity: 1;
                color: #5c707b;
            }
        }
    }
}

.grayBg {
    background-color: #eaeef4;
}